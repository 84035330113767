(function($) {

	var mobileMenu = function(){

		var actionOpen 		= $('#openMenuMobile'),
			actionClose 	= $('#btnCloseMenu'),
			menuContainer 	= $('#menuMobileContainer'),
			$that 			= $(this);

		actionOpen.on('click', function(){
			menuContainer.addClass("open");
			console.log("click");
		});

		actionClose.on('click', function(){
			menuContainer.removeClass('open');
		});

	};
	
	var lightbox = function(){
		$(document).on('click', '[data-toggle="lightbox"]', function(event) {
			event.preventDefault();
			$(this).ekkoLightbox({
				alwaysShowClose: true,
			});
		});
	};

	var loadMore = function(){
		$('#btn_cliente_loadmore').on('click', function(){

			if($(this).hasClass('menos')){
				$(this).text("Mostrar Mais").removeClass('menos');

			}else{
				$(this).text("Mostrar Menos").addClass('menos');
			}

			$('.hide').fadeToggle('fast', function(){
				$(this).addClass('on');

			});

		});
	};

	var startScripts = function(){
		mobileMenu();
		lightbox();
		loadMore();
	};
	startScripts();

	

})(jQuery); // Fully reference jQuery after this point.

